<template>
    <List pageType="admin"></List>
</template>

<script>
    import List from "@/views/modules/personal/collect/list.vue";

    export default {
        components: {
            List,
        },
    }
</script>

<style scoped>

</style>
